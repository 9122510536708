export const FC_HYPERMX_BASE_URL = "canvas.casino.featureConfig.adsRestUrlHyperMx";
export const FC_ADS_PROVIDERS = "canvas.casino.featureConfig.adsProviderInformation";
export const FC_SHOW_HILO = "canvas.casino.featureConfig.showHighLowGame";
export const FC_LOADING_GAMESLIST = "canvas.casino.featureConfig.LoadingGamesList";
export const FC_LOADING_MIGRATIONPOPUP = "canvas.casino.featureConfig.migrationpopups";
export const FC_ADS_TOGGLE = "casino.canvas.ads.toggle";
export const FC_EVENTS_ENGINEERING = "casino.canvas.events.engineering";
export const FC_ADS_REFRESH_RATE = "casino.canvas.ads.refreshrate";
export const FC_ADS_WHITELIST = "casino.canvas.adsDisplayConfig.countriesWhereAdsCanRefresh";
export const FC_CONSENT_CONFIG = 'casino.features.consent.config';
export const FC_WEBGL_ROLLOUT = 'canvas.casino.featureConfig.webglRollout';
export const FC_CLEAR_INDEXDB = 'canvas.casino.featureConfig.isClearIndexdbRequired';
export const FC_ISPLAYGAMI_PAYMENT = 'canvas.casino.featureConfig.isPlaygamipayment';
export const FC_WEB_ADS_PROVIDER = 'canvas.casino.featureConfig.webAdsProvider';
export const CARD_DECK = [{'card': 'c-2','value':2}, {'card': 'c-3','value':3}, {'card': 'c-4','value':4}, {'card': 'c-5','value':5}, {'card': 'c-6','value':6}, {'card': 'c-7','value':7}, {'card': 'c-8','value':8}, {'card': 'c-9','value':9}, {'card': 'c-10','value':10}, {'card': 'c-j','value':11}, {'card': 'c-q','value':12}, {'card': 'c-k','value':13}, {'card': 'c-a','value':14}, {'card': 'd-2','value':2}, {'card': 'd-3','value':3}, {'card': 'd-4','value':4}, {'card': 'd-5','value':5}, {'card': 'd-6','value':6}, {'card': 'd-7','value':7}, {'card': 'd-8','value':8}, {'card': 'd-9','value':9}, {'card': 'd-10','value':10}, {'card': 'd-j','value':11}, {'card': 'd-q','value':12}, {'card': 'd-k','value':13}, {'card': 'd-a','value':14}, {'card': 'h-2','value':2}, {'card': 'h-3','value':3}, {'card': 'h-4','value':4}, {'card': 'h-5','value':5}, {'card': 'h-6','value':6}, {'card': 'h-7','value':7}, {'card': 'h-8','value':8}, {'card': 'h-9','value':9}, {'card': 'h-10','value':10}, {'card': 'h-j','value':11}, {'card': 'h-q','value':12}, {'card': 'h-k','value':13}, {'card': 'h-a','value':14}, {'card': 's-2','value':2}, {'card': 's-3','value':3}, {'card': 's-4','value':4}, {'card': 's-5','value':5}, {'card': 's-6','value':6}, {'card': 's-7','value':7}, {'card': 's-8','value':8}, {'card': 's-9','value':9}, {'card': 's-10','value':10}, {'card': 's-j','value':11}, {'card': 's-q','value':12}, {'card': 's-k','value':13}, {'card': 's-a','value':14}];
export const COUNTRY_LOOKUP = ['AT','BE','BG','CY','CZ','DE','DK','EE','EL','ES','FI','FR','HR','HU','IE','IT','LT','LU','LV','MT','NL','PL','PT','RO','SE','SI','SK','UK','GB','GR',''];
export const RUSSIAN_COUNTRY_LOOKUP = ['RU','BY'];
export const PUBLISHER_ID_GSN_FB =  process.env.PUBLISHER_ID_GSN_FB;
export const ZONE_ID_ADS_FB = process.env.ZONE_ID_ADS_FB;
export const LOGGER_DATA = {fbInfo:null,seamInfo:null, userCountry:null, method:null, fileName:null, lineNumber:null, message:null};
export const ENG_ERROR = { eventGroup: 'ENGINEERING', eventCode: 'SEAM SERVICE ERROR'};
export const ENG_EVENT = { eventGroup: 'ENGINEERING', eventSubCode: 'SUCCESS', eventCode:'ROLLOUT_APP'};
export const FC_MINI_GAME_MATH = "canvas.casino.miniGame.gameMath";
export const FC_MINI_GAME_MAX_TOKENS = "gameMath.highLow.tokens.max";
export const updateMacLinks = { Chrome:'https://www.google.com/chrome/', Edge:'https://www.microsoft.com/en-us/windows/microsoft-edge', Safari:'https://www.apple.com/safari/', Firefox:'https://www.mozilla.org/en-US/firefox/new/',};
export const updateWinLinks = { Chrome:'https://www.google.com/chrome/', Edge:'https://www.microsoft.com/en-us/windows/microsoft-edge', Safari:'https://www.apple.com/safari/', Firefox:'https://www.mozilla.org/en-US/firefox/windows/',};
export const SCREEN_RATIO = 0.5625;
export const ZOOM_LEVEL_PIX_RATIO = [{_key:.46, _v:-5 },{_key:.54, _v:-4 },{_key:.64, _v:-3},{_key:.76, _v:-2},{_key:.92, _v:-1},{_key:1.10, _v:0},{_key:1.32, _v:1},{_key:1.58, _v:2},{_key:1.90, _v:3},{_key:2.28, _v:4},{_key:2.70, _v:5},{_key:99, _v:99}];
export const ZOOM_LEVEL_PIX_RATIO_FF = [{_key:1.052, _v:-5 },{_key:1.088, _v:-4 },{_key:1.09, _v:-3 },{_key:1.121, _v:-2},{_key:1.15, _v:-1},{_key:1.175, _v:0},{_key:1.19, _v:1},{_key:1.2, _v:2},{_key:1.241, _v:3},{_key:1.26, _v:4},{_key:1.28, _v:5},{_key:1.32, _v:6},{_key:99, _v:99}];
export const ZOOM_LEVEL_PIX_RATIO_IE = [{_key:1.048506, _v:-3 },{_key:1.10458, _v:-2 },{_key:1.149154, _v:-1},{_key:1.1975, _v:0},{_key:1.25080, _v:1},{_key:1.30793, _v:2},{_key:1.37235, _v:3},{_key:1.29959, _v:4},{_key:1.153846, _v:5},{_key:1.037786, _v:6},{_key:0.94197, _v:7}, {_key:0.862348, _v:8}, {_key:0.79622, _v:9}, {_key:0.73819, _v:10}, {_key:0.688259, _v:11}, {_key:0.643724, _v:12}, {_key:0.60593, _v:13}, {_key:0.572199, _v:14}, {_key:0.54116, _v:15}, {_key:0.512820, _v:16}, {_key:0.4885290, _v:17}, {_key:0.4655870, _v:18}, {_key:0.445344, _v:19}, {_key:0.010000, _v:99}];
export const ZOOM_REST_LIST = ['Firefox', 'Edge', 'Internet Explorer'];
export const PUBSUB_EVENTS_LIST = {ADD_TOKENS_METHOD:'ADD_TOKENS_METHOD',ERROR_OCCURED_IN_PAYMENT: 'ERROR_OCCURED_IN_PAYMENT', PROGRESS_INDICATOR:'PROGRESS_INDICATOR', GAME_NAME:'NEW_GAME', OPEN_CONTACT_SUPPORT:'OPEN_CONTACT_SUPPORT', SEAM_SERVICE_ERROR:'SEAM_SERVICE_ERROR', ESC_FULL_SCREEN:'ESC_FULL_SCREEN', WEBGL_LOADED:'WEBGL_LOADED', WEBGL_IS_LOADED:'WEBGL_IS_LOADED', VIDEO_ADS_AVAILABLE:'VIDEO_ADS_AVAILABLE',VIDEO_ADS_COMPLETED:'VIDEO_ADS_COMPLETED', PLAY_VIDEO_ADS: 'PLAY_VIDEO_ADS', initializeIronSource: 'initializeIronSource', initializeAds: 'initializeAds', GSN_PAYPAL_PAYMENT:'GSN_PAYPAL_PAYMENT','BLOCK_USER_TYPING': 'BLOCK_USER_TYPING', REDIRECT_PAYMENT:'REDIRECT_PAYMENT'};
export const FC_ADS_RewardVideo = "casino.canvas.features.ads.rewardVideo";
export const PLATFORM_GSNCOM = process.env.PLATFORM_GSNCOM;
export const PLATFORM_FB = process.env.PLATFORM_FB;
export const GAMES_TOUPDATE = ['American Buffalo Slots','Wheel of Fortune Vegas Slots','Video Bingo','Classic 777 Slots','Blazing Cherries Slots','Deuces Wild Slots','Undersea Treasure Slots','Mt Rockmore Slots','Northern Wolf Slots','Wheel of Fortune Slots','Outlaw Video Poker','Shiny Shamrock Slots','Mondo Bingo','Entering the Lobby'];

